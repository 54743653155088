import MainPage from '@/components/dashboard/home/Home';
import Navbar from '@/components/layouts-and-navs/Navbar';

function App() {
  return <MainPage />;
}

App.hideSocialAndGas = true;
App.isPublic = true;
App.metadata = {
  title: 'NettyWorth –  Crypto & NFT Loan Protocol',
  description: 'Get instant Crypto and NFT loans with NettyWorth’s loan marketplace. Borrow and lend against Crypto, NFTs, and Gaming Assets, receive borrowing and lending alerts, and make informed lending decisions with NettyScore, a Web3 credit score that tracks borrower loan history.',
  image: `${process.env.NEXT_PUBLIC_SITE_URL}/thumbnail.png`,
  contentType: 'product',
};

App.hideSidebar = true;

App.CustomNavbar = function CustiomNavbar() {
  return <Navbar />;
};

export default App;
