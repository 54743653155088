import { useEffect, useState } from 'react';

const notifications = [
  {
    emoji: '/images/great-news-emoji.png',
    name: 'Put Your Crypto to Work!',
    info: 'Start lending now and earn interest on your assets.',
  },
  {
    emoji: '/images/offer-emoji.png',
    name: "You've Got an Offer!",
    info: 'Review the details to accept the loan.',
  },
  {
    emoji: '/images/put-your-crypto-to-work-emoji.png',
    name: 'Unlock Extra Capital!',
    info: 'One of your assets is eligible for a loan.',
  },
  {
    emoji: '/images/unlock-emoji.png',
    name: 'Great news!',
    info: 'Your portfolio is up by 37%! View your portfolio now.',
  },
];

const AlertsArticle: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % notifications.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [notifications.length]);

  return (
    <>
      <img className='ss:max-w-[232px] w-full h-auto self-center shadow-light-100 rounded-t-[40px]' alt='netty worth app on mobile phone svg' src='/hero-page-mobile.png' />
      <div className='flex flex-col gap-10 max-w-[326px] fader-container absolute ss:top-[153px] top-[122px] left-0 right-0 mx-auto z-10'>
        {notifications.map(({ emoji, name, info }, idx) => (
          <div key={idx} className={`absolute w-[calc(100%-32px)] lg:w-full left-0 right-0 mx-auto flex gap-3 items-center lg:p-5 p-4 !bg-white fader-item animate__animated rounded-3xl ${activeIndex === idx ? 'animate__fadeInUp' : 'animate__fadeOutUp'}`}>
            <img src={emoji} alt='emoji' className='lg:size-[34px] size-[27px]' />
            <div>
              <p className='font-semibold !text-coal-250 text-xs lg:text-sm/[21px]'>{name}</p>
              <p className='text-[9px]/[14px] lg:text-xs/[17px] !text-coal-250 font-normal'>{info}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default AlertsArticle;
