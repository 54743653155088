import Link from 'next/link';

const statisticsData = [
  {
    id: 1,
    value: '$2.1B+',
    title: 'Total Loan Market',
    mobileTitle: 'Loan \n Market',
  },
  {
    id: 2,
    value: '$15k',
    title: 'Avg Loan Size',
    mobileTitle: 'Loan \n Size',
  },
  {
    id: 3,
    value: '25%',
    title: 'Avg Monthly APR',
    mobileTitle: 'Monthly \n APR',
  },
];

const Header: React.FC = () => {
  return (
    <header>
      <div className='pt-[78px] lg:pt-[169px] flex flex-col items-center'>
        <div className='w-full max-w-[1686px] mx-auto pb-[72px] flex flex-col items-center bg-hero-coins bg-no-repeat bg-center bg-cover'>
          <hgroup className='lg:max-w-[1078px] max-w-[328px] mx-auto px-[25px] flex flex-col items-center'>
            <h1 className='font-semibold text-center text-[32px]/9 lg:text-7xl/[80px] !text-transparent dark:!text-transparent bg-clip-text bg-[radial-gradient(50%_50%_at_50%_50%,_#000_0%,#474747_100%)] dark:bg-[radial-gradient(50%_111.11%_at_50%_50%,#fff_77.5%,#ffffff99_100%)]'>Borrow Against Your Assets To Unlock More Crypto</h1>

            <p className='mt-6 lg:mt-[45px] text-center max-w-[228px] mx-auto lg:max-w-full text-base lg:text-2xl font-medium text-primary-grey-400 dark:!text-white-dark'>Get alerts on borrowing and lending opportunities.</p>

            <Link href='/login' className='mt-[39px] lg:mt-[45px] w-full lg:w-[228px] h-[42px] flex justify-center items-center rounded !bg-primary text-white text-base/5 font-medium hover:opacity-75 active:scale-95'>
              Launch App
            </Link>
          </hgroup>
        </div>
        <div className='flex flex-col items-center max-w-[1357px] mx-auto px-[25px] w-full'>
          <div className='max-w-[1211px] w-full'>
            <div className='w-full h-full bg-top bg-mobile-home md:bg-hero [mask-image:linear-gradient(#000_0%_86%,transparent_96%)] bg-no-repeat aspect-[1/0.63] sm:aspect-[1.2/0.50] bg-cover md:bg-center'></div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
