import type { IconProps } from 'types';

const IconBorrowerRequestLoan: React.FC<IconProps> = ({ className, width = 110, height = 110, fill = 'transparent', pathFill = '#EDF6FF', gradientColor = '#7480FE' }) => {
  return (
    <svg className={className} width={width} height={height} viewBox='0 0 110 110' fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <rect x='108.75' y='108.75' width='107.5' height='107.5' rx='22.75' transform='rotate(180 108.75 108.75)' />
      <rect x='108.75' y='108.75' width='107.5' height='107.5' rx='22.75' transform='rotate(180 108.75 108.75)' stroke='#1160FF' stroke-width='2.5' />
      <path d='M23.6488 1.5V108.5M2 86.6023L109 86.6023M55.5 1.5V108.5M2 54.7512L109 54.7512M87.3512 1.5V108.5M2 22.9L109 22.9' stroke='url(#paint0_linear_8864_27946)' stroke-opacity='0.3' stroke-width='0.5' />
      <path
        d='M51.6673 60.333C53.2586 60.333 54.7847 60.9651 55.91 62.0904C57.0352 63.2156 57.6673 64.7417 57.6673 66.333V70.3383L57.646 70.6263C56.8193 76.2983 51.726 79.0237 43.1793 79.0237C34.6647 79.0237 29.4887 76.3303 28.3713 70.725L28.334 70.333V66.333C28.334 64.7417 28.9661 63.2156 30.0913 62.0904C31.2166 60.9651 32.7427 60.333 34.334 60.333H51.6673ZM43.0007 38.9997C44.2263 38.9997 45.44 39.2411 46.5724 39.7101C47.7047 40.1792 48.7336 40.8667 49.6003 41.7333C50.467 42.6 51.1545 43.6289 51.6235 44.7613C52.0926 45.8937 52.334 47.1073 52.334 48.333C52.334 49.5587 52.0926 50.7723 51.6235 51.9047C51.1545 53.0371 50.467 54.066 49.6003 54.9327C48.7336 55.7994 47.7047 56.4868 46.5724 56.9559C45.44 57.4249 44.2263 57.6663 43.0007 57.6663C40.5253 57.6663 38.1513 56.683 36.401 54.9327C34.6506 53.1823 33.6673 50.8084 33.6673 48.333C33.6673 45.8577 34.6506 43.4837 36.401 41.7333C38.1513 39.983 40.5253 38.9997 43.0007 38.9997ZM75.6673 28.333C77.2586 28.333 78.7847 28.9651 79.91 30.0904C81.0352 31.2156 81.6673 32.7417 81.6673 34.333V43.6663C81.6673 44.4543 81.5121 45.2345 81.2106 45.9624C80.9091 46.6904 80.4671 47.3518 79.91 47.909C79.3528 48.4661 78.6914 48.9081 77.9634 49.2096C77.2355 49.5111 76.4552 49.6663 75.6673 49.6663H71.526L64.75 55.5517C64.3638 55.8873 63.8893 56.1047 63.383 56.1781C62.8767 56.2514 62.3599 56.1776 61.8944 55.9654C61.4289 55.7531 61.0343 55.4115 60.7576 54.9811C60.4809 54.5508 60.3339 54.0499 60.334 53.5383V49.629C58.8673 49.465 57.5125 48.7662 56.5286 47.6662C55.5447 46.5662 55.0007 45.1422 55.0006 43.6663V34.333C55.0006 32.7417 55.6328 31.2156 56.758 30.0904C57.8832 28.9651 59.4093 28.333 61.0006 28.333H75.6673Z'
        fill={pathFill}
      />
      <defs>
        <linearGradient id='paint0_linear_8864_27946' x1='55.5005' y1='108.5' x2='55.5005' y2='1.50047' gradientUnits='userSpaceOnUse'>
          <stop offset='1' stop-color={gradientColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IconBorrowerRequestLoan;
