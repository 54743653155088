import Image, { StaticImageData } from 'next/image';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { cn } from '@/utils/helper';

interface IHomeSwiper {
  reverse?: boolean;
  data: {
    name: string;
    text: string;
    img: StaticImageData;
  }[];
}

const HomeSwiper: React.FC<IHomeSwiper> = ({ reverse, data }) => {
  return (
    <Swiper
      spaceBetween={30}
      loop={true}
      autoplay={{
        delay: 0,
        disableOnInteraction: false,
        reverseDirection: reverse,
      }}
      modules={[Autoplay]}
      speed={6000}
      className={cn('home-swiper flex !bg-transparent [&_.swiper-slide]:!bg-transparent', !reverse ? '[&_.swiper-wrapper]:pl-56' : '')}
      breakpoints={{
        1700: {
          slidesPerView: 4.2,
          spaceBetween: 30,
        },
        1200: {
          slidesPerView: 3.25,
        },
        1024: {
          slidesPerView: 2.8,
        },
        500: {
          slidesPerView: 2.1,
        },
        320: {
          slidesPerView: 1.2,
        },
      }}
    >
      {[...data, ...data].map((feedback, idx) => {
        return (
          <SwiperSlide key={idx} className='my-[15px]'>
            <div className='py-7 px-[22px] rounded-[22px] bg-white-100 dark:bg-[linear-gradient(180deg,_#242325_0%,_#171619_100%)] max-w-[434px] min-[1366px]:max-w-max w-full h-[246px] lg:h-[300px] xl:h-[246px] border border-white-100 dark:border-[#2f2e32] [box-shadow:0px_1px_2px_0px_rgba(82,81,84,0.05)_inset,_0px_20px_25px_-5px_rgba(0,0,0,0.05),0px_10px_10px_-5px_rgba(0,0,0,0.05)] dark:[box-shadow:0px_1px_2px_0px_#525154_inset,_0px_20px_25px_-5px_rgba(0,_0,_0,_0.80),_0px_10px_10px_-5px_rgb(0,_0,_0,_0.90)]'>
              <div className='flex gap-2 lg:gap-3 items-center !bg-transparent [box-shadow:0px_1px_2px_0px_#525154_inset,_0px_20px_25px_-5px_rgba(0, 0, 0, 0.80),_0px_10px_10px_-5px_rgba(0, 0, 0, 0.90)]'>
                <Image alt={feedback.name} width={28} height={28} src={feedback.img.src} className='size-7 rounded-full object-cover' />
                <p className='px-[14px] bg-white dark:bg-primary-grey-120 rounded-[58px] dark:text-white text-coal-250 font-medium text-sm/8'>{feedback.name}</p>
              </div>
              <p className='mt-4 lg:mt-[25px] text-base lg:text-xl font-normal text-coal-250 dark:text-white' dangerouslySetInnerHTML={{ __html: feedback.text }}></p>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default HomeSwiper;
