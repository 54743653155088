import { useState } from 'react';

import Link from 'next/link';

import FaqAccordionItem from './FaqAccordionItem';

const faqData = [
  {
    id: 1,
    question: 'What is NettyWorth?',
    answer: 'NettyWorth is an AI-loan protocol matching borrowers with lenders for digital and real-world assets (RWAs).',
  },
  {
    id: 2,
    question: 'How do I create an account?',
    answer: (
      <>
        Sign up on{' '}
        <Link href='/login' rel='noopener noreferrer' target='_blank' className='text-primary hover:opacity-75'>
          nettyworth.io/login
        </Link>
        , create an account, and connect your crypto wallet. Once connected, you will receive borrowing and lending alerts.
      </>
    ),
  },
  {
    id: 3,
    question: 'What are the key benefits for lenders?',
    answer: "Lenders receive alerts for new lending opportunities, enabling them to maximize returns on their crypto by providing loans to borrowers. NettyScore, a Web3 credit system, offers insights into borrowers' loan histories, including repayments and defaults, ensuring confident and informed lending decisions.",
  },
  {
    id: 4,
    question: 'Is NettyWorth safe to use? ',
    answer: " NettyWorth's AI Loan Protocol underwent its latest smart contract audit on November 26th, 2024. Resonance conducted this comprehensive audit to ensure the protocol's security, reliability, and compliance with the highest industry standards.",
  },
  {
    id: 5,
    question: 'What fees does NettyWorth charge?',
    answer: 'Lenders on NettyWorth pay a 4% protocol fee, deducted from borrower-paid interest. The rest goes to the lender. No fee applies if a loan defaults.',
  },
  {
    id: 6,
    question: 'Which blockchain is supported?',
    answer: 'NettyWorth runs on the Ethereum blockchain and currently supports lending through WETH (Wrapped Ether) and USDT (Tether). Additional assets will be introduced in the future.',
  },
  {
    id: 7,
    question: 'What is peer-to-peer lending?',
    answer: 'Peer-to-peer (P2P) lending is a financial system where individuals can borrow and lend money directly to one another without traditional intermediaries like banks.',
  },
  {
    id: 8,
    question: 'What happens if a borrower defaults?',
    answer: "If a loan isn't repaid on time, the collateralized asset locked in the smart contract escrow is transferred to the lender as compensation. This ensures that lenders are protected and the process remains secure and transparent.",
  },
];

const FaqSection: React.FC = () => {
  const [activeId, setActiveId] = useState<number | null>(null);

  const toggleAccordion = (id: number) => {
    setActiveId(activeId === id ? null : id);
  };

  const leftFaqData = faqData.slice(0, Math.ceil(faqData.length / 2));
  const rightFaqData = faqData.slice(Math.ceil(faqData.length / 2));

  return (
    <section id='faq' className='px-[25px]'>
      <div className='max-w-[1112px] mx-auto mt-[142px] lg:mt-[193px]'>
        <h2 className='w-fit mx-auto text-center text-[28px]/9 lg:text-[52px]/[70px] font-semibold text-black lg:!text-transparent dark:text-white lg:dark:!text-transparent bg-clip-text bg-[radial-gradient(53%_111.11%_at_50%_50%,_#1A1A1A_83%,#0C0C0C99_100%)] dark:bg-[radial-gradient(50%_111.11%_at_50%_50%,#FFF_82.64%,#ffffff99_100%)]'>Frequently Asked Questions</h2>

        <div className='flex flex-wrap gap-3 mt-12 lg:mt-16'>
          <ul className='space-y-3 w-[calc(50%-12px)] flex-1 min-w-full md:min-w-[384px] lg:min-w-[550px]'>
            {leftFaqData.map((item) => (
              <FaqAccordionItem key={item.id} item={item} isActive={activeId === item.id} onToggle={() => toggleAccordion(item.id)} />
            ))}
          </ul>
          <ul className='space-y-3 w-[calc(50%-12px)] flex-1 min-w-full md:min-w-[384px] lg:min-w-[550px]'>
            {rightFaqData.map((item) => (
              <FaqAccordionItem key={item.id} item={item} isActive={activeId === item.id} onToggle={() => toggleAccordion(item.id)} />
            ))}
          </ul>
        </div>

        <a href='mailto:info@nettyworth.io' rel='noopener noreferrer' className='mt-12 lg:mt-16 w-full md:w-[235px] h-14 text-center flex items-center justify-center !bg-primary rounded lg:text-[19px]/5 text-base/5 font-medium text-white mx-auto hover:opacity-75 active:scale-95'>
          Contact us
        </a>
      </div>
    </section>
  );
};

export default FaqSection;
