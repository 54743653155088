import Link from 'next/link';

import { useTheme } from '@/hooks/useTheme';

import IconBorrowRequest from '../../../../assets/icons/IconBorrowerRequestLoan';
import IconLoanCompletion from '../../../../assets/icons/IconIoanCompletion';
import IconLenderRequest from '../../../../assets/icons/IconLenderReviewsFunds';

type WorksDataType = {
  id: number;
  Icon: React.ElementType;
  title: string;
  desc: string;
};

const worksData: WorksDataType[] = [
  {
    id: 1,
    Icon: IconBorrowRequest,
    title: 'Borrower Requests a Loan',
    desc: 'Borrower selects an asset as collateral, specifies the loan amount, and sets the terms.',
  },
  {
    id: 2,
    Icon: IconLenderRequest,
    title: 'Lender Reviews and Funds',
    desc: 'Lender approves the terms, collateral is secured in escrow, and funds are released to the borrower',
  },
  {
    id: 3,
    Icon: IconLoanCompletion,
    title: 'Loan Completion',
    desc: 'Borrower repays the loan to retrieve their asset, or the lender claims ownership if the borrower defaults.',
  },
];

const HowWorksSection: React.FC = () => {
  const { mode } = useTheme();

  return (
    <section className='px-[25px]'>
      <div className='flex flex-col items-center mt-16 lg:mt-10'>
        <hgroup className='max-w-[1112px] mx-auto flex flex-col items-center'>
          <h2
            className='bg-clip-text
           bg-[radial-gradient(#2B2B2B_23%,#00000099_100%)]
           dark:bg-[radial-gradient(#fff_23%,#ffffff99_100%)] text-center font-semibold text-[28px]/9 lg:text-[52px]/[60px] !text-transparent dark:!text-transparent'
          >
            How NettyWorth Works
          </h2>
          <p className='mt-6 lg:mt-8 text-center text-base lg:text-xl/8 lg:font-medium font-normal dark:!text-white-dark !text-primary-grey-400'>NettyWorth simplifies the loan process, making borrowing and lending easy.</p>
        </hgroup>

        <Link href='/login' className='mt-9 lg:mt-8 w-full ss:w-[260px] h-14 flex justify-center items-center rounded !bg-primary text-white text-base/5 font-semibold hover:opacity-75 active:scale-95'>
          Get Started
        </Link>

        <div className='flex flex-col items-center mt-[97px] lg:mt-[107px]'>
          <img src={mode === 'light' ? '/work-cycle-light.svg' : '/work-cycle-dark.svg'} alt='netty-worth work cycle svg' className='w-[81%] h-auto max-[1189px]:hidden flex' />

          <div className='flex justify-center flex-wrap w-full min-[1189px]:mt-20 lg:gap-[75px] gap-20'>
            {worksData.map(({ id, Icon, title, desc }) => {
              return (
                <article key={id} className='flex flex-col items-center max-w-[325px]'>
                  <div className='[&_.a:stop-color:#ff1122]'>
                    <Icon className='max-[1189px]:flex hidden' fill={mode === 'light' ? 'transparent' : '#1160FF'} pathFill={mode === 'light' ? '#1160FF' : 'white'} />
                  </div>
                  <h3 className='mt-16 text-base lg:text-2xl/9 font-medium text-black dark:!text-white-light'>{title}</h3>
                  <p className='mt-4 text-center text-base lg:text-lg/8 font-medium text-primary-grey-150 dark:!text-white-other'>{desc}</p>
                </article>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowWorksSection;
