import { homeFeedback } from 'constant';

import HomeSwiper from './HomeSwiper';

const Feedback = () => {
  return (
    <section id='feedback' className='mt-[148px] lg:mt-[254px] '>
      <hgroup className='mb-16 lg:mb-[94px]'>
        <h2 className='w-fit mx-auto text-center font-semibold text-[28px]/9 lg:text-[52px] lg:leading-normal text-black lg:!text-transparent dark:text-white lg:dark:!text-transparent bg-clip-text bg-[radial-gradient(52%_111.11%_at_50%_50%,_#000_82.64%,#17161699_100%)] dark:bg-[radial-gradient(50%_111.11%_at_50%_50%,#FFF_82.64%,#ffffff99_100%)]'>What our users are saying</h2>
        <p className='mt-6 lg:mt-8 text-center text-base lg:text-lg/8 font-medium max-w-[517px] mx-auto text-primary-grey-550 dark:text-white-dark'>Real feedback from our community on how NettyWorth is transforming their DeFi experience.</p>
      </hgroup>

      <div className='grid'>
        <HomeSwiper reverse={true} data={homeFeedback} />
        <HomeSwiper data={homeFeedback} />
      </div>
    </section>
  );
};

export default Feedback;
