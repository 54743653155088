import Image from 'next/image';

import { useTheme } from '@/hooks/useTheme';

const logos = [
  {
    id: 1,
    lightSrc: '/outlier-ventures-light.png',
    darkSrc: '/outlier-ventures-dark.png',
    alt: 'Outlier Ventures logo',
  },
  {
    id: 2,
    lightSrc: '/republic-light.png',
    darkSrc: '/republic-dark.png',
    alt: 'Republic logo',
  },
  {
    id: 3,
    lightSrc: '/trive-light.png',
    darkSrc: '/trive-dark.png',
    alt: 'Trive logo',
  },
  {
    id: 4,
    lightSrc: '/blockchain-light.png',
    darkSrc: '/blockchain-dark.png',
    alt: 'Blockchain Founders Fund logo',
  },
];

const VentureBackedArticle: React.FC = () => {
  const { mode } = useTheme();

  return (
    <article className='px-[25px] max-w-[1112px] mx-auto mt-12 lg:mt-[100px]'>
      <div className='lg:px-[95px] flex flex-col gap-[30px] lg:pt-[27px] p-7 lg:pb-[82px] bg-white-100 dark:bg-grey-coal rounded-[18px]'>
        <h2 className='text-center text-sm/[10px] lg:text-lg/8 font-semibold text-primary'>Venture Backed</h2>
        <ul className='flex items-center flex-wrap justify-around gap-x-14 gap-y-7'>
          {logos.map((logo) => (
            <li key={logo.id} className='lg:max-w-[174px] max-w-[105px] w-full'>
              <Image width={137} height={48} className='w-full h-auto object-contain' src={mode === 'light' ? logo.lightSrc : logo.darkSrc} alt={logo.alt} quality={100} />
            </li>
          ))}
        </ul>
      </div>
    </article>
  );
};

export default VentureBackedArticle;
